import React, { Suspense } from "react";
import { IAcceptAll } from "../../../../common/interfaces";
import { Route, Switch } from "react-router-dom";
import SignUpChooser from "./signupChooser";
import SignUpUser from "./signupUser";
import Signupcommon from "../../client/Signup/Signupcommon";

const SignupLayout: React.FC<IAcceptAll> = (props) => {

  return (
    <>
      {/* <WebHeader isAdmin={false} showAuthRoutes={true}/> */}

      <div className="home-header-divider">

      </div>
      <Suspense fallback={"loading"}>
        <Switch>

            <Route
              exact={true}
              path="/signup"
              component={SignUpChooser}
              name="Sign Up"
            />
            <Route
              exact={true}
              path="/signup/user"
              component={Signupcommon}
              name="Sign Up"
            />

        </Switch>
      </Suspense>
      {/* <WebFooter /> */}
    </>
  );
};

export default SignupLayout;
