import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IsLoggedIn } from "../../../../common/utility";
import { Link, useHistory } from "react-router-dom";
import Signup from "./Signup";
import SignUp2 from "./SignUp2";
import SignUp3 from "./SignUp3";
import SignUp4 from "./SignUp4";
import SignUp5 from "./SignUp5";
import SignUp6 from "./SignUp6";
import SignUp7 from "./SignUp7";
import { PostData } from "../../../../common/apiServices";
import useToast from "../../../../common/hooks/useToast";


const defaultValues = {
  id: 0,
  firstName: "",
  lastName: "",
  password: "",
  roleId: 0,
  isBlocked: false,
  email: "",
  gender: "",
  dob: "",
  phoneNumber: "",
  location: "",
  parentalStatus: "",
  maritalStatus: "",
  educationLevel: "",
  occupation: "",
  country: "",
  state: "",
  city: "",
  town: "",
  pinCode: "",
  address1: "",
  address2: "",
  fileId: 0,
  companyName: "",
  companyAddress: "",
  companyWebsite: "",
  companyRepContactNumber: "",
  referralCode: "",
  questionAnswer: []
};

const Signupcommon = () => {
  const history = useHistory();
  // const [signup, setSignup] = useState(() => {
  //   const storedPage = localStorage.getItem('signupPage');
  //   return storedPage ? parseInt(storedPage) : 1;
  // });
  const [signup, setSignup] = useState(1)
  const [formData, setFormData] = useState(() => {
    const storedData = localStorage.getItem('signupFormData');
    return storedData ? JSON.parse(storedData) : { ...defaultValues };
  });
  const { showToast } = useToast()
  const [answers, setAnswers] = useState({});
  const [error, setError] = useState('');

  useEffect(() => {
    if (IsLoggedIn()) {
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('signupPage', signup.toString());
    localStorage.setItem('signupFormData', JSON.stringify(formData));
  }, [signup, formData]);

  const onSubmit = async (data: any) => {
    // Merge the current form data with the new data from this step
    const updatedData = { ...formData, ...data };
    let updatedQuestionAnswer = [...formData.questionAnswer];

    // Clear previous error message
    setError('');

    // Password and confirm password validation
    if (signup === 1 && data.password !== data.cPassword) {
      setError('Password do not match');
      return;
    }
    // Only update the question answers for the specified steps
    if (signup === 4 || signup === 5 || signup === 6 || signup === 7) {
      const keys = Object.keys(data);
      const finArr = keys.map(key => ({
        questionId: Number(key),
        answer: data[key]
      }));
      updatedQuestionAnswer = [...updatedQuestionAnswer, ...finArr];
    }
    updatedData.questionAnswer = updatedQuestionAnswer;
    // Update the formData state and localStorage with the new data
    setFormData(updatedData);
    localStorage.setItem('signupFormData', JSON.stringify(updatedData));
    // If not the final step, increment the signup step and update localStorage
    if (signup < 7) {
      setSignup(signup + 1);
    } else {
      // If it's the final step, prepare and send the payload
      const payload = {
        id: 0,
        firstName: updatedData?.firstName,
        lastName: updatedData.lastName,
        password: updatedData.password,
        roleId: 2,
        isBlocked: false,
        email: updatedData.email,
        gender: updatedData.gender,
        // dob: `${updatedData.day }${updatedData.month}${updatedData?.year}`,
        phoneNumber: updatedData.phoneNumber,
        location: updatedData.location,
        parentalStatus: updatedData?.parentalStatus,
        maritalStatus: updatedData?.maritalStatus,
        educationLevel: updatedData?.educationLevel,
        occupation: updatedData?.occupation,
        country: updatedData.country,
        state: updatedData.state,
        city: updatedData.city,
        town: updatedData.town,
        pinCode: updatedData.pinCode,
        address1: updatedData.address1,
        address2: updatedData.address2,
        fileId: 0,
        companyName: updatedData.companyName,
        companyAddress: updatedData.companyAddress,
        companyWebsite: updatedData.companyWebsite,
        companyRepContactNumber: updatedData.companyRepContactNumber,
        referralCode: updatedData.referralCode,
        questionAnswer: updatedData.questionAnswer
      };
      try {
        const response = await PostData("User/Save", { ...payload });
        if (response && response?.status === 200) {
          showToast("success", response.message)
          localStorage.removeItem('signupFormData');

          // toast.success(response.message)
          history.push("/login");
        }
        else {
          showToast("error", response.message)
          // toast.error(response.message)

        }
      } catch (error) {
        console.log(error, "errr");
      }
    }
  };

  const { control, handleSubmit, reset, errors } = useForm({
    defaultValues: { ...defaultValues },
  });
  const showError = (_fieldName: string, p0: { type: string; message: string; }) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="error-block">{error.message || "Field Is Required"}</div>
    ) : null;
  };
  const updateQuestionAnswer = (questionId: any, answer: any) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  const prviewBack = () => {
    setSignup(signup - 1)
  }
  useEffect(() => {
    reset(formData);
  }, [reset, formData]);

  return (
    <div className="login">
      <div className="container">
        <div className="row">
          <div className="col-md-7 main-img">
            <img
              style={{ transform: "translateY(30%)" }}
              src="/images/QUARANTINE_5_-_LANDING_PAGE_ILUSTRASI_7_png (1).png"
              alt="logo"
            />
          </div>
          <div className="col-md-5">

            <form onSubmit={handleSubmit(onSubmit)}>
              {signup === 1 && (
                <Signup
                  errorMessage={showError}
                  control={control}
                  error={error}
                />
              )}
              {signup === 2 && (
                <SignUp2
                  errorMessage={showError}
                  control={control}
                />
              )}
              {signup === 3 && (
                <SignUp3
                  errorMessage={showError}
                  control={control}
                />
              )}
              {signup === 4 && (
                <SignUp4
                  errorMessage={showError}
                  control={control}
                  updateQuestionAnswer={updateQuestionAnswer}
                  answers={answers}
                />
              )}
              {signup === 5 && (
                <SignUp5
                  errorMessage={showError}
                  control={control}
                  updateQuestionAnswer={updateQuestionAnswer}
                />
              )}
              {signup === 6 && (
                <SignUp6
                  errorMessage={showError}
                  control={control}
                  updateQuestionAnswer={updateQuestionAnswer}

                />
              )}
              {signup === 7 && (
                <SignUp7
                  errorMessage={showError}
                  control={control}
                  updateQuestionAnswer={updateQuestionAnswer}

                />
              )}
              <div className="d-flex justify-content-between align-items-center">
                {signup > 1 &&
                  <button className="pink-btn" type="button" onClick={() => prviewBack()}>Back</button>
                }
                <button
                  className={"pink-btn"}
                  type="submit"
                >{signup === 7 ? "Submit" : "Next"} </button>
                {signup === 1 &&
                  <div>
                    <label className="already-account w-100">
                      Already have an account?
                      <Link to="/login">
                        <a> Sign in</a>
                      </Link>
                    </label>
                  </div>
                }

              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Signupcommon;
