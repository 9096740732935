import { title } from "process";

export const questinsData = [
  {
    title: "What best describes your current household?",
    id: 1,
    answer: [
      { value: "rented_apartment", label: "Rented apartment" },
      { value: "owned_apartment", label: "Owned apartment" },
      { value: "rented_single_family_home", label: "Rented Single Family Home" },
      { value: "owned_single_family_home", label: "Owned Single Family Home" },
      { value: "rented_multi_family_residence", label: "Rented Multi-Family Residence (Duplex, Triplex)" },
      { value: "owned_multi_family_residence", label: "Owned Multi-Family Residence (Duplex, Triplex)" },
      { value: "farm", label: "Farm" },
      { value: "university_residence", label: "University Residence" },
      { value: "living_with_parents", label: "Living with parents" },
      { value: "other", label: "Other" },
      { value: "prefer_not_to_say", label: "Prefer not to say" },
    ],
  },
  {
    title: "How many people live in your household?",
    id: 2,
    answer: [
      { value: "1_person", label: "1 Person" },
      { value: "2_persons", label: "2 persons" },
      { value: "3_persons", label: "3 persons" },
      { value: "4_persons", label: "4 persons" },
      { value: "5_persons", label: "5 persons" },
      { value: "6_persons", label: "6 persons" },
      { value: "7_persons", label: "7 persons" },
      { value: "8_persons", label: "8 persons" },
      { value: "9_persons", label: "9 persons" },
      { value: "10_or_more", label: "10 or more" },
      { value: "prefer_not_to_say", label: "Prefer not to say" },
    ],
  },
  {
    title: "Please choose the option which describes the best household",
    id: 3,
    answer: [
      { value: "pregnant_or_expecting", label: "I am pregnant/expecting a child within the next 9 months" },
      { value: "children_under_18", label: "I have one or more children under the age of 18 living in my household" },
      { value: "children_over_18", label: "I have one or more children over the age of 18 living in my household" },
      { value: "no_children", label: "I have no children living in my household and I am not pregnant/expecting a child within the next 9 months" },
    ],
  },
  {
    title: "Please indicate the age and the gender of your child or children?",
    id: 4,
    answer: [
      { value: "boy_under_1", label: "Boy under age 1" },
      { value: "girl_under_1", label: "Girl under age 1" },
      { value: "boy_age_1", label: "Boy age 1" },
      { value: "girl_age_1", label: "Girl age 1" },
      { value: "boy_age_2", label: "Boy age 2" },
      { value: "girl_age_2", label: "Girl age 2" },
      { value: "boy_age_3", label: "Boy age 3" },
      { value: "girl_age_3", label: "Girl age 3" },
      { value: "boy_age_4", label: "Boy age 4" },
      { value: "girl_age_4", label: "Girl age 4" },
      { value: "boy_age_5", label: "Boy age 5" },
      { value: "girl_age_5", label: "Girl age 5" },
      { value: "boy_age_6", label: "Boy age 6" },
      { value: "girl_age_6", label: "Girl age 6" },
      { value: "boy_age_7", label: "Boy age 7" },
      { value: "girl_age_7", label: "Girl age 7" },
      { value: "boy_age_8", label: "Boy age 8" },
      { value: "girl_age_8", label: "Girl age 8" },
      { value: "boy_age_9", label: "Boy age 9" },
      { value: "girl_age_9", label: "Girl age 9" },
      { value: "boy_age_10", label: "Boy age 10" },
      { value: "girl_age_10", label: "Girl age 10" },
      { value: "boy_age_11", label: "Boy age 11" },
      { value: "girl_age_11", label: "Girl age 11" },
      { value: "boy_age_12", label: "Boy age 12" },
      { value: "girl_age_12", label: "Girl age 12" },
      { value: "male_teen_age_13", label: "Male teen age 13" },
      { value: "female_teen_age_13", label: "Female teen age 13" },
      { value: "male_teen_age_14", label: "Male teen age 14" },
      { value: "female_teen_age_14", label: "Female teen age 14" },
      { value: "male_teen_age_15", label: "Male teen age 15" },
      { value: "female_teen_age_15", label: "Female teen age 15" },
      { value: "male_teen_age_16", label: "Male teen age 16" },
      { value: "female_teen_age_16", label: "Female teen age 16" },
      { value: "male_teen_age_17", label: "Male teen age 17" },
      { value: "female_teen_age_17", label: "Female teen age 17" },
      { value: "none_of_the_above", label: "None of the above" },
    ],
  },
  {
    title: "Which of the following pets are present in your household?",
    id: 5,
    answer: [
      { value: "amphibians", label: "Amphibians" },
      { value: "bird", label: "Bird" },
      { value: "cat", label: "Cat" },
      { value: "dog", label: "Dog" },
      { value: "fish", label: "Fish (fresh water)" },
      { value: "horse", label: "Horse" },
      { value: "reptiles", label: "Reptiles" },
      { value: "small_animals", label: "Small animals/rodents" },
      { value: "other", label: "Other" },
      { value: "no_pets", label: "No pets" },
    ],
  },
  {
    title: "What is the highest education you have completed?",
    id: 6,
    answer: [
      { value: "some_secondary_school", label: "Some secondary school or less" },
      { value: "finished_secondary_school", label: "Finished secondary school" },
      { value: "some_vocational_school", label: "Some vocational school/technical college" },
      { value: "vocational_school", label: "Vocational school/technical college" },
      { value: "some_university", label: "Some university/higher education" },
      { value: "university_degree", label: "University degree, Honours degree" },
      { value: "masters", label: "Masters" },
      { value: "doctorate", label: "Doctorate" },
      { value: "none_of_the_above", label: "None of the above/Prefer not to say" },
    ],
  },
  {
    title: "What is your relationship status?",
    id: 7,
    answer: [
      { value: "single_never_married", label: "Single, never married" },
      { value: "domestic_partnership", label: "Domestic Partnership" },
      { value: "married", label: "Married" },
      { value: "separated", label: "Separated" },
      { value: "prefer_not_to_say", label: "Prefer not to say" },
      { value: "none_of_the_above", label: "None of the above" },
    ],
  },
  {
    title: "What is your current employment status?",
    id: 8,

    answer: [
      { value: "employed_full_time", label: "Employed full-time" },
      { value: "employed_part_time", label: "Employed part-time" },
      { value: "self_employed_full_time", label: "Self-employed full-time" },
      { value: "self_employed_part_time", label: "Self-employed part-time" },
      { value: "active_military", label: "Active military" },
      { value: "inactive_military", label: "Inactive military/Veteran" },
      { value: "temporarily_unemployed", label: "Temporarily unemployed" },
      { value: "full_time_homemaker", label: "Full-time homemaker" },
      { value: "retired", label: "Retired" },
      { value: "student", label: "Student" },
      { value: "disabled", label: "Disabled/Permanently Unemployed" },
      { value: "prefer_not_to_answer", label: "Prefer not to answer" },
    ],
  },
  {
    title: "Which of the following categories best describes your organization's primary industry?",
    id: 9,
    answer: [
      { value: "accounting", label: "Accounting" },
      { value: "advertising", label: "Advertising" },
      { value: "agriculture", label: "Agriculture, Forestry, & Fishing and Hunting" },
      { value: "architecture", label: "Architecture" },
      { value: "automotive", label: "Automotive" },
      { value: "aviation", label: "Aviation" },
      { value: "banking_financial", label: "Banking/Financial" },
      { value: "bio_tech", label: "Bio-Tech" },
      { value: "brokerage", label: "Brokerage" },
      { value: "skilled_trades", label: "Skilled Trades" },
      { value: "chemicals", label: "Chemicals/Plastics/Rubber" },
      { value: "communications", label: "Communications/Information" },
      { value: "compliance", label: "Compliance / Risk Management" },
      { value: "computer_hardware", label: "Computer Hardware" },
      { value: "computer_reseller", label: "Computer Reseller (software/hardware)" },
      { value: "computer_software", label: "Computer Software" },
      { value: "construction", label: "Construction" },
      { value: "consulting", label: "Consulting" },
      { value: "consumer_electronics", label: "Consumer Electronics" },
      { value: "consumer_packaged_goods", label: "Consumer Packaged Goods" },
      { value: "customer_service", label: "Customer Service" },
      { value: "energy", label: "Energy/Utilities/Oil and Gas" },
      { value: "engineering", label: "Engineering" },
      { value: "environmental_services", label: "Environmental Services" },
      { value: "fashion_apparel", label: "Fashion/Apparel" },
      { value: "food_and_consumer_products", label: "Food and Consumer Products" },
      { value: "government", label: "Government/Public Sector" },
      { value: "health_care", label: "Health Care and Social Assistance" },
      { value: "hospitality_tourism", label: "Hospitality/Tourism" },
      { value: "human_resources", label: "Human Resources" },
      { value: "information_technology", label: "Information Technology/IT" },
      { value: "insurance", label: "Insurance" },
      { value: "internet", label: "Internet" },
      { value: "legal_law", label: "Legal/Law" },
      { value: "logistics", label: "Logistics / Planning" },
      { value: "manufacturing", label: "Manufacturing" },
      { value: "market_research", label: "Market Research" },
      { value: "marketing", label: "Marketing" },
      { value: "media_entertainment", label: "Media/Entertainment" },
      { value: "military", label: "Military" },
      { value: "non_profit", label: "Non Profit/Social services" },
      { value: "operations_production", label: "Operations / Production" },
      { value: "other_business_services", label: "Other Business Services" },
      { value: "personal_services", label: "Personal Services" },
      { value: "pharmaceuticals", label: "Pharmaceuticals" },
      { value: "printing_publishing", label: "Printing Publishing" },
      { value: "public_relations", label: "Public Relations" },
      { value: "purchasing_procurement", label: "Purchasing / Procurement" },
      { value: "quality_assurance", label: "Quality Assurance / Quality Control" },
      { value: "real_estate", label: "Real Estate/Property" },
      { value: "retail", label: "Retail" },
      { value: "sales", label: "Sales" },
      { value: "security", label: "Security" },
      { value: "shipping_distribution", label: "Shipping/Distribution" },
      { value: "telecommunications", label: "Telecommunications" },
      { value: "transportation_warehousing", label: "Transportation and Warehousing" },
      { value: "prefer_not_to_answer", label: "Prefer not to answer" },
    ],
  },
  {
    title: "Which department do you primarily work in at your organization?",
    id: 10,
    answer: [
      { value: "administration", label: "Administration/General Staff" },
      { value: "customer_service", label: "Customer Service/Client Service" },
      { value: "executive_leadership", label: "Executive Leadership" },
      { value: "finance_accounting", label: "Finance/Accounting" },
      { value: "human_resources", label: "Human Resources" },
      { value: "legal_law", label: "Legal/Law" },
      { value: "marketing", label: "Marketing" },
      { value: "operations", label: "Operations" },
      { value: "procurement", label: "Procurement" },
      { value: "product_management", label: "Product Management" },
      { value: "sales_business_development", label: "Sales/Business Development" },
      { value: "technology_development_hardware", label: "Technology Development Hardware (not only IT)" },
      { value: "technology_development_software", label: "Technology Development Software (not only IT)" },
      { value: "technology_implementation", label: "Technology Implementation" },
      { value: "other", label: "Other" },
      { value: "prefer_not_to_say", label: "Prefer not to say" },
      { value: "i_dont_work", label: "I don't work" },
    ],
  },
  {
    title: "Please choose which departments/products you have influence or decision making authority on spending/purchasing?",
    id: 11,
    answer: [
      { value: "auto_leasing_purchasing", label: "Auto leasing/purchasing" },
      { value: "corporate_travel", label: "Corporate travel" },
      { value: "facilities", label: "Facilities" },
      { value: "financial_department", label: "Financial Department" },
      { value: "food_services", label: "Food services" },
      { value: "human_resources", label: "Human Resources" },
      { value: "it_hardware", label: "IT Hardware" },
      { value: "it_software", label: "IT Software" },
      { value: "legal_services", label: "Legal services" },
      { value: "marketing_advertising", label: "Marketing/Advertising" },
      { value: "office_supplies", label: "Office supplies" },
      { value: "operations", label: "Operations" },
      { value: "printers_and_copiers", label: "Printers and copiers" },
      { value: "sales", label: "Sales" },
      { value: "security", label: "Security" },
      { value: "shipping", label: "Shipping" },
      { value: "telecommunications", label: "Telecommunications" },
      { value: "utilities", label: "Utilities" },
      { value: "other", label: "Other" },
      { value: "no_influence", label: "I don't have influence or decision making authority" },
    ],
  },
  {
    title: "What best describes your job title?",
    id: 12,
    answer: [
      { value: "administrative", label: "Administrative (Clerical or Support Staff)" },
      { value: "analyst", label: "Analyst" },
      { value: "assistant_or_associate", label: "Assistant or Associate" },
      { value: "c_level", label: "C-Level (e.g., CEO, CFO), Owner, Partner, President" },
      { value: "consultant", label: "Consultant" },
      { value: "manager", label: "Manager (Group Manager, Sr. Manager, Manager, Program Manager)" },
      { value: "vice_president", label: "Vice President" },
      { value: "volunteer", label: "Volunteer" },
      { value: "others_none_of_the_above", label: "Others/None of the above" },
      { value: "does_not_apply", label: "Does not apply" },
    ],
  },
  {
    title: "Approximately how many employees work at your organization (all locations)?",
    id: 13,
    answer: [
      { value: "less_than_300000", label: "Less than ₹300,000" },
      { value: "300000_to_399999", label: "₹300,000 to ₹399,999" },
      { value: "400000_to_499999", label: "₹400,000 to ₹499,999" },
      { value: "500000_to_599999", label: "₹500,000 to ₹599,999" },
      { value: "600000_to_699999", label: "₹600,000 to ₹699,999" },
      { value: "700000_to_799999", label: "₹700,000 to ₹799,999" },
      { value: "800000_to_899999", label: "₹800,000 to ₹899,999" },
      { value: "900000_to_999999", label: "₹900,000 to ₹999,999" },
      { value: "1_to_1.19_million", label: "₹1 to ₹1.19 million" },
      { value: "1.20_to_1.49_million", label: "₹1.20 to ₹1.49 million" },
      { value: "1.5_to_1.69_million", label: "₹1.5 to ₹1.69 million" },
      { value: "1.70_to_1.99_million", label: "₹1.70 to ₹1.99 million" },
      { value: "2_to_2.49_million", label: "₹2 to ₹2.49 million" },
      { value: "2.50_to_2.99_million", label: "₹2.50 to ₹2.99 million" },
      { value: "3_to_3.49_million", label: "₹3 to ₹3.49 million" },
      { value: "3.50_to_3.99_million", label: "₹3.50 to ₹3.99 million" },
      { value: "4_million_and_above", label: "₹4 million and above" },
      { value: "prefer_not_to_answer", label: "Prefer not to answer" },
    ],
  },
  {
    title: "What is your current household income?",
    id: 14,
    answer: [
      { value: "less_than_300000", label: "Less than 300,000" },
      { value: "300000_to_399999", label: "300,000 to 399,999" },
      { value: "400000_to_499999", label: "400,000 to 499,999" },
      { value: "500000_to_599999", label: "500,000 to 599,999" },
      { value: "600000_to_699999", label: "600,000 to 6,99,999" },
      { value: "700000_to_799999", label: "700,000 to 799,999" },
    ],
  },
  {
    title: "In your household are you the person who makes most of the daily purchasing decisions?",
    id: 15,
    answer: [
      { value: "all_decisions", label: "Yes, All decisions" },
      { value: "about_half", label: "About Half" },
      { value: "less_than_half", label: "Less than Half" },
    ],
  },
  {
    title: "In which hometown region do you live?",
    id: 16,
    answer: [
      { value: "east_india", label: "East India" },
      { value: "north_east_india", label: "North-East India" },
      { value: "north_india", label: "North India" },
      { value: "south_india", label: "South India" },
      { value: "west_india", label: "West India" },
    ],
  },
  {
    title: "What languages do you speak?",
    id: 17,
    answer: [
      { value: "english", label: "English" },
      { value: "arabic", label: "Arabic" },
      { value: "chinese", label: "Chinese" },
      { value: "dutch", label: "Dutch" },
      { value: "french", label: "French" },
      { value: "german", label: "German" },
      { value: "hindi", label: "Hindi" },
      { value: "italian", label: "Italian" },
      { value: "japanese", label: "Japanese" },
      { value: "korean", label: "Korean" },
      { value: "portuguese", label: "Portuguese" },
      { value: "russian", label: "Russian" },
      { value: "spanish", label: "Spanish" },
      { value: "vietnamese", label: "Vietnamese" },
      { value: "others", label: "Others" },
    ],
  },
];



