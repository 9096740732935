import React, { useEffect, useState } from "react";
import { IApiCallStatus } from "../../../../common/interfaces";
import { questinsData } from "../../../../common/data";
import { GetAllQuestions } from "../../../../common/apiServices";
import SelectCtrl from "../../../../controls/SelectCtrl";
import { Link } from "react-router-dom";

const SignUp4 = ({ errorMessage, control, updateQuestionAnswer , answers }: any) => {
  const [questions, SetQuestions] = useState<any>([]);
  const [ApiStaus, SetApiStatus] = useState<IApiCallStatus>({
    InProgress: false,
    Failed: false,
    FailMessage: "",
  });
  const fetchSignupQuestins = async () => {
    SetApiStatus({ ...ApiStaus, InProgress: true });

    try {
      const resp = await GetAllQuestions(`Question/GetAll`, 1, 20, 1);
      console.log(resp, "resssss")
      if (resp.data) {
        SetQuestions(resp?.data?.items)
      }
      SetApiStatus({ ...ApiStaus, InProgress: false });
    } catch (err) {
      console.log(err);
    }
  };
  const handleInputChange = (questionId: number, answer: any) => {
    // Update the answers state with the new answer
    updateQuestionAnswer(questionId, answer);
  };



    const LoadOptions = (item: any, index: number) => {
      try {
        const foundOptions = questinsData.find((el: any) => el?.id === item?.id
        );
        if (foundOptions) {
          return (
            <SelectCtrl
              control={control}
              showError={errorMessage}
              options={foundOptions?.answer?.map((questions: any) => {
                return {
                  value: questions.value,
                  label: questions.label
                }
              })}
              placeholder=" Select Answers"
              name={foundOptions.id.toString()}
              required={true}
              className="form-control"
              disabled={ApiStaus.InProgress}
              onChange={(event: any) => handleInputChange(item.id, event.target.value)}
            />
          );

        }
      } catch (error) {
        console.log("error", error);
      }
      return null;
    };

    useEffect(() => {
      fetchSignupQuestins();
    }, []);

    return (
      <>
        <div className="container">
          <div className="row">
            <div className="">
              <div className="logo text-center">
                <Link to="/">
                    <img src="/images/logo.svg" alt="logo" />
                </Link>
              </div>
              <div className="p-3">
                <div className="d-flex justify-content-between">
                  <h5>Sign Up</h5>
                  <h5>Step 4-7</h5>
                </div>
                {questions.map((item: any, index: number) => {
                  return (
                    <>
                      <h5 className="my-3" >{item.question}</h5>
                      {LoadOptions(item, index)}
                    </>
                  );
                })}

              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  export default SignUp4;
