import axios from "axios";
import { BASE_URL as API_BASE, ValueFromUserData } from "./utility";
//Axios Interceptor //
// Request
axios.interceptors.request.use(
  function (config) {
    console.log("intercept-req");
    const token = ValueFromUserData("token");

    config.headers["Content-Type"] = "application/json";

    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

// LOGIN
export const LoginUser = async (body: object) => {
  console.log(`${API_BASE}/api/Login/Login`, body);
  var response = await axios.post<any>(`${API_BASE}/api/Login/Login`, body);
  console.log(response.data);
  return { data: response.data.data, message: response.data.message };
};

// GET REQUEST
export const GetData = async (END_POINT: string, id?: any) => {
  let response: any;

  if (id) {
    response = await axios.get<any>(API_BASE + END_POINT, {
      params: id,
    });
  } else {
    response = await axios.get<any>(API_BASE + END_POINT);
  }

  return { data: response.data.data, message: response.data.message, status: response.data.status };
};


export const GetViewData = async (END_POINT: string, timeSpan?: any) => {
  let response: any;

    response = await axios.get<any>(API_BASE + END_POINT);
  

  return { data: response.data.data, message: response.data.message, status: response.data.status };
};

export const GetGraphData = async (END_POINT: string, timeSpan?: any) => {
  let response: any;

    response = await axios.get<any>(API_BASE + END_POINT);
  

  return { data: response.data.data, message: response.data.message, status: response.data.status };
};

//Recent submission 
export const GetSubmission = async (END_POINT: string,pageNo: number = 1, pageSize: number = 10) => {
  let response: any;

    response = await axios.get<any>(`${API_BASE}${END_POINT}?pageNo=${pageNo}&pageSize=${pageSize}`);
  

  return { data: response.data.data, message: response.data.message, status: response.data.status };
};

// popular Survey 
export const GetSurvey = async (END_POINT: string,pageNo: number = 1, pageSize: number = 10) => {
  let response: any;

    response = await axios.get<any>(`${API_BASE}${END_POINT}?pageNo=${pageNo}&pageSize=${pageSize}`);
  

  return { data: response.data.data, message: response.data.message, status: response.data.status };
};

// recent Payments
// popular Survey 
export const GetPayments = async (END_POINT: string,pageNo: number = 1, pageSize: number = 20) => {
  let response: any;

    response = await axios.get<any>(`${API_BASE}${END_POINT}?pageNo=${pageNo}&pageSize=${pageSize}`);
  

  return { data: response.data.data, message: response.data.message, status: response.data.status };
}; 
// signup questions 
export const GetAllQuestions = async (END_POINT: string,pageNo: number = 1, pageSize: number = 20,type:number) => {
  let response: any;

    response = await axios.get<any>(`${API_BASE}${END_POINT}?pageNo=${pageNo}&pageSize=${pageSize}&type=${type}`);
  

  return { data: response.data.data, message: response.data.message, status: response.data.status };
}; 



export const getDataWithQuery = async (END_POINT: string, queryObject: any) => {
  let response: any;

  if (queryObject) {
    response = await axios.get<any>(API_BASE + END_POINT, {
      params: queryObject,
    });
  } else {
    response = await axios.get<any>(API_BASE + END_POINT);
  }

  return { data: response.data.data, message: response.data.message };
};

// POST REQUEST
export const PostData = async (END_POINT: string, body: object) => {
  console.log(END_POINT, body);
  var response = await axios.post<any>(API_BASE + END_POINT, body);

  return { data: response.data.data, message: response.data.message, status: response.data.status, errors: response.data.errors };
};

export const PostDataWithParams = async (END_POINT: string, body: any, params: object) => {
  console.log(END_POINT, body);
  var response = await axios.post<any>(API_BASE + END_POINT, body, params);

  return { data: response.data.data, message: response.data.message, status: response.data.status, errors: response.data.errors };
};

//DELETE REQUEST
export const DeleteData = async (END_POINT: string, id: any) => {
  // id = Number(id);

  const response = await axios.delete<any>(`${API_BASE}${END_POINT}`, {
    params: id,
  });
  return { data: response.data, message: response.data.message };
};

export const DeleteFile = async (body: Array<number>) => {
  console.log('body', body);

  const response = await axios.delete<any>(`${API_BASE}Files/DeleteFile`, {
    data: body.filter(val => val > 0)
  });
  return { data: response.data, message: response.data.message };
};

export const PostDataForm = async (END_POINT: string, data: any, config?: any) => {
  const formData = new FormData();
  for (let item in data) {
    formData.append(item, data[item]);
  }
  var response = await axios.post<any>(API_BASE + END_POINT, formData, config);

  return { data: response.data.data, message: response.data.message };
}


export const saveUserTestimonial = async (email: string) => {
  const response = await axios.get<any>(window.runConfig['wordpressUrl'] + 'wp-admin/admin-ajax.php?action=save_testimonials&email=' + email);
  if(response.data.status === 200) {
    return { data: true, message: response.data.message };
  } else {
    return { data: null, message: response.data.message };
  }
}

export const uploadFile = (data: any, onUploadProgress:any) => { 
  PostDataForm('Files/Upload', data, {
    onUploadProgress: (progressEvent: any) => {
      onUploadProgress({event: 'load', value: `${Math.ceil((progressEvent.loaded/progressEvent.total) * 100)}`});
    }
  }).then((val) => {
    console.log(val.data);
    onUploadProgress({event: 'complete', value: val});
  });
}
