import React, { useEffect, useState } from "react";
import {
  // ILoginForm,
  IApiCallStatus,
  IAcceptAll
} from "../../../common/interfaces";
import { useForm } from "react-hook-form";

import { useHistory } from "react-router";
import InputCtrl from "../../../controls/InputCtrl";
import { IsLoggedIn } from "../../../common/utility";
import { GetData } from "../../../common/apiServices";

// set the default values for the controls
let initialValues = {
  email: ''
};

const RecoverPassword: React.FC<IAcceptAll> = (props: IAcceptAll) => {
  const history = useHistory();

  const [ApiStaus, SetApiStatus] = useState<IApiCallStatus>({
    InProgress: false,
    Failed: false,
    FailMessage: ""
  });

  const { control, handleSubmit, errors, reset } = useForm<any>({
    defaultValues: initialValues,
    mode: "onBlur" // when the you blur... check for errors
  });

  useEffect(() => {
    if (IsLoggedIn()) {
      // Default re-direct
    }
  }, [reset]);

  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="error-block">{error.message || "Field Is Required"}</div>
    ) : null;
  };

  const onSubmit = async (data: any) => {
    SetApiStatus({
      ...ApiStaus,
      InProgress: true,
    })
    try {
      console.log(data, "DATA");
      const saveUser = await GetData('User/ForgotPassword', data);
      if (saveUser.data) {
        SetApiStatus({
          ...ApiStaus,
          InProgress: false
        })

        history.push("/dashboard");
      } else {
        SetApiStatus({
          ...ApiStaus,
          InProgress: false,
          Failed: true,
          FailMessage: saveUser.message
        })
      }
    } catch (error) {
      console.log(error.message);

    }
  };

  return (
    <>
      {/* <WebHeader isAdmin={false} showAuthRoutes={false} /> */}
      <hr style={{ borderTop: '1px solid #0D8D85' }}/>
      <div>
            <div className="container user-signup-container">
                <div className="row">
                    <div className="col-md-6">
                        <img
                            src="/images/QUARANTINE_5_-_LANDING_PAGE_ILUSTRASI_7_png (1).png"
                            alt="logo"
                        />
                    </div>
                    <div className="col-md-6">
                        <div className="signup-form">


                            <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="signup-animation" style={{padding: '1.8rem'}}>
                                            <div className="signup-header">
                                                <h3>Recover Password</h3>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <h5>No worries! Enter your email and we will send you a link to reset your password.</h5>
                                                </div>
                                            </div>
                                            <InputCtrl
                                                control={control}
                                                showError={showError}
                                                type="email"
                                                placeholder="Enter Your Email"
                                                name="email"
                                                required={true}
                                                className="form-control"
                                                componentName="signup"
                                                disabled={ApiStaus.InProgress}
                                            />

                                            {ApiStaus.InProgress || ApiStaus.Failed ? (
                                                <div className="form-check">
                                                    <label className="text-danger" style={{fontSize: '1rem'}}>
                                                        {ApiStaus.InProgress
                                                            ? "Please wait..."
                                                            : ApiStaus.FailMessage}
                                                    </label>
                                                </div>
                                            ) : null}
                                            <div className="row submit_row">
                                                <div className="col-md-12 requestBtn">
                                                    <button disabled={ApiStaus.InProgress} type="submit" className='pink-btn complete'>
                                                        Send Request
                                                    </button>

                                                </div>
                                            </div>
                                        </div>

                                {
                                    // <>


                                    //     <div className={inputIndex === 3 ? 'signup-animation signup-animation-center' : 'signup-animation  signup-animation-right'}>



                                    //         {ApiStaus.InProgress || ApiStaus.Failed ? (
                                    //             <div className="form-check">
                                    //                 <label className="text-danger">
                                    //                     {ApiStaus.InProgress
                                    //                         ? "Please wait..."
                                    //                         : ApiStaus.FailMessage}
                                    //                 </label>
                                    //             </div>
                                    //         ) : null}
                                    //         <div className="row submit_row">
                                    //             <div className="col-md-12">
                                    //                 <button onClick={onInputIndexChange} type="button" className={inputIndex === 3 ? 'pink-btn complete' : 'pink-btn'} disabled={ApiStaus.InProgress}>
                                    //                     {
                                    //                         inputIndex === 3 ? 'Complete Signup' : 'Next'
                                    //                     }
                                    //                 </button>

                                    //             </div>
                                    //         </div>

                                    //     </div>
                                    // </>
                                }


                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
      {/* <WebFooter /> */}
    </>
  );
};

export default RecoverPassword;
