import React from "react";
import styles from "./webpage.module.scss";
import { Link } from "react-router-dom";

const navItems = [
  {
    name: "Contact",
    target: "#",
    clickable: () => {
      window.location.href = window.runConfig["wordpressUrl"] + "contact";
    },
  },
  {
    name: "Blog",
    target: "#",
    clickable: () => {
      window.location.href = window.runConfig["wordpressUrl"];
    },
  },
  {
    name: "FAQ",
    target: "#",
    clickable: () => {
      window.location.href =
        window.runConfig["wordpressUrl"] + "frequently-asked-questions/";
    },
  },
  // {
  //   name: "Subscribe",
  //   target: "#",
  //   clickable: () => {
  //     window.location.href =
  //       window.runConfig["wordpressUrl"] + "frequently-asked-questions/";
  //   },
  // },
];
const footerSocials = [
  {
    icon: "fab fa-facebook-f",
    target: "https://www.facebook.com/GrapevineAfrica",
    image: "/images/footer/facebook (18) 1.png",
  },
  {
    icon: "fab fa-twitter",
    target: "https://x.com/_GrapevineAfric?s=20",
    image: "/images/footer/twitter (13).png" ,
  },
  {
    icon: "fab fa-instagram",
    target: "https://instagram.com/grapevineafrica",
    image: "/images/footer/instagram (8).png" ,
  },
  {
    icon: "fab fa-linkedin-in",
    target: "https://www.linkedin.com/company/grapevine-africa/",
    image: "/images/footer/linkedin (14).png",
  },
  {
    icon: "fab fa-youtube",
    target: "https://www.youtube.com/@Grapevinegh",
    image: "/images/footer/youtube (8).png",
  },
];

const WebFooter = () => {
  return (
    <div className={styles.webFooter}>
      <div className={styles.footer_links}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className={styles.footerNav}>
                {navItems.map((val, idx) => {
                  return (
                    <Link
                      key={idx}
                      to={val.target}
                      onClick={val.clickable ? val.clickable : () => {}}
                    >
                      {val.name}
                    </Link>
                  );
                })}
              </div>
            </div>
            {/* <div className="col-md-6">
                <div className={styles.footerSocial}>
                  <span>
                    {footerSocials.map((val, idx) => {
                      return (
                        <Link to={val.target}>
                          <i className={val.icon}></i>
                        </Link>
                      );
                    })}
                  </span>
                </div>
              </div> */}
          </div>
        </div>
      </div>

      <div className={styles.footerBar}>
        <div className="container">
          <div className={styles.footerBarSpan}>
            <span>©2021. All rights reserved</span>
            <br />
            <span>
            {footerSocials.map((val, idx) => {
                      return (
                        <a href={val.target} rel="noopener noreferrer" target="_blank">
                           <img src={val.image}/>
                        </a>
                      );
                    })}
              {/* <Link to="#">
                <img src="/images/footer/facebook (18) 1.png" />
              </Link>
              <Link to="#">
                <img src="/images/footer/instagram (8).png" />
              </Link>
              <Link to="#">
                <img src="/images/footer/linkedin (14).png" />
              </Link>
              <Link to="#">
                <img src="/images/footer/youtube (8).png" />
              </Link>
              <Link to="#">
                <img src="/images/footer/twitter (13).png" />
              </Link> */}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebFooter;
