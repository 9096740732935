import Noty from "noty";
import moment from "moment";
declare global {
  interface Window {
    runConfig: any;
    reloadHeader: any;
  }
}
//
export const BASE_URL = window.runConfig.BaseURL;
export const UserDataKey = window.runConfig.Keys.UserData;
export const ADMINROLEID = window.runConfig.adminRoleId || 1;
export const QUESTION_TYPES: {
  label: string;
  value: string;
  statsAllowed: boolean;
}[] = [
  {
    label: "Multiple Choice",
    value: "MULTIPLECHOICE",
    statsAllowed: true,
  },
  {
    label: "Checkboxes",
    value: "CHECKBOXES",
    statsAllowed: true,
  },
  {
    label: "Single Textbox",
    value: "SINGLETEXTBOX",
    statsAllowed: false,
  },
  {
    label: "Dropdown",
    value: "DROPDOWN",
    statsAllowed: true,
  },
  {
    label: "Multiple Textboxes",
    value: "MULTIPLETEXTBOXES",
    statsAllowed: false,
  },
  {
    label: "Date / Time",
    value: "DATETIME",
    statsAllowed: false,
  },
  {
    label: "Checkboxes - Images",
    value: "IMAGECHECKBOXES",
    statsAllowed: true,
  },
  {
    label: "Multiple Choice - Images",
    value: "IMAGEMULTIPLECHOICE",
    statsAllowed: true,
  },
];
export const MONTHS_ORDER = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
//

export const IsLoggedIn = () => {
  return ReadLocalStorage(UserDataKey) != undefined;
};

export const ValueFromUserData = (key: string) => {
  let UserData: any = ReadLocalStorage(UserDataKey);

  if (UserData != undefined && UserData != null) {
    UserData = JSON.parse(UserData);
    // console.log(UserData, key)
    return UserData[key];
  } else {
    return "";
  }
};

export const ReduceString = (text: string) => {
  return text.trim().replace(/ /g, "").toLowerCase();
};

export const SetLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const ReadLocalStorage = (key: string) => {
  let result = localStorage.getItem(key);
  if (result == undefined || result == null) {
    return undefined;
  } else {
    return result;
  }
};

export const RemoveFromLocalStorage = (key: string) => [
  localStorage.removeItem(key),
];

// NOTIFICATION //
type NotificationType =
  | "alert"
  | "success"
  | "warning"
  | "info"
  | "error"
  | "information"
  | undefined;

export const NotificationHandler = (
  notyType: NotificationType,
  message: string
) => {
  console.log("in notification");
  const notification = new Noty({
    type: notyType,
    // layout: "topCenter",
    text: `<h2>${message}</h2>`,
    timeout: 2000,
  });
  notification.show();
};
//-//

export const StandardDateFormat = (Date: any) => {
  return moment(Date).format("MMM DD, YYYY");
};

export const FormatCurrency = (value: string | number) => {
  if (value == undefined || value == null) return `$0`;
  return `$${value}`;
};

export const FilterRecords = async (
  Records: any[] = [],
  Keys: string[] = [],
  SearchString: string = ""
): Promise<any[]> => {
  let FilteredArray: any = [];
  return new Promise<any[]>((resolve) => {
    Records.forEach((val: any, idx: number) => {
      for (let iterator = 0; iterator < Keys.length; iterator++) {
        if (
          val[Keys[iterator]] != null &&
          val[Keys[iterator]] != undefined &&
          ReduceString(val[Keys[iterator]].toString()).includes(
            ReduceString(SearchString)
          )
        ) {
          FilteredArray.push(val);
          break;
        }
      }
      //   return obj.VAL.indexOf(value) !== -1;

      if (Records.length == idx + 1) {
        resolve(FilteredArray);
      }
    });
  });
};

export const SummarizeText = (text: string, count: number) => {
  if (text == undefined || text == null) {
    return "";
  }

  if (text.length <= count) {
    return text;
  }

  return text.substr(0, count) + "...";
};

export const fullDateFormat = (date: string) => {
  return moment(date).format("MMMM DD YYYY, h:mm a");
};

export const getMonthDateYear = (date: string) => {
  return moment(date).format("MMMM DD, YYYY");
};

export const readFileAsDataUrl = (file: any) => {
  return new Promise((res) => {
    const reader = new FileReader();
    reader.onload = (val) => {
      res(reader.result as string);
    };
    reader.readAsDataURL(file);
  });
};

export const disableScrollOnBody = (canDisable: boolean) => {
  const overflow = canDisable ? "hidden" : "auto";
  document.getElementsByTagName("body")[0].style.overflow = overflow;
};

export const createCookie = (
  name: string,
  value: string,
  domain: string = window.runConfig["CookieDomain"],
  days: number = 7
) => {
  // 1 week valid
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie =
    name + "=" + value + ";" + expires + ";domain=" + domain + ";path=/";
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getSearchQuery = (query: string, value: string) => {
  const queryParser = new URLSearchParams(query);
  return queryParser.has(value) ? queryParser.get(value) : null;
};

export const CalculatePercentage = (num: any, denom: any) => {
  if (num && denom && denom != 0) {
    let typeNum = typeof num;
    let typeDenom = typeof denom;
    if (
      (typeNum === "string" || typeNum === "number") &&
      (typeDenom === "string" || typeDenom === "number")
    ) {
      let result: any = ((num / denom) * 100).toFixed(2);
      if (!isNaN(result)) {
        return result;
      }
    }
  }
  return 0;
};

export const getAge = (birthDateString: any) => {
  try {
    const birthDate = moment(birthDateString);

    const today = moment();

    let age = today.diff(birthDate, "years");

    if (today.isBefore(birthDate.clone().add(age, "years"))) {
      age--;
    }

    return age;
  } catch (error) {
    console.log("error", error);
  }
  return "NA";
};

export const countByKey = (array: any[], key: string) => {
  try {
    return array.reduce((acc, curr) => {
      const value = curr[key];
      if (acc[value]) {
        acc[value] += 1;
      } else {
        acc[value] = 1;
      }
      return acc;
    }, {});
  } catch (error) {
    console.log("error", error);
  }
  return {};
};

export const countByMonth = (array: any[], key: string) => {
  const months = {
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  };

  return array.reduce((acc, curr) => {
    const date = moment(curr[key]);
    const monthName = date.format("MMMM");
    if (acc[monthName] !== undefined) {
      acc[monthName] += 1;
    }
    return acc;
  }, months);
};
