import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "./scss/style.scss";
import "antd/dist/antd.css";
import AppLayout from "./containers/layout/appLayout";
import { SnackbarProvider, useSnackbar } from "notistack";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

class App extends Component {
  render() {
    return (
      <HashRouter>
        <SnackbarProvider>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              path="/"
              render={(props) => <AppLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
        </SnackbarProvider>
      </HashRouter>
    );
  }
}

export default App;
