import React, { Component } from "react";
import { Controller } from "react-hook-form";
import { IInputProps } from "../common/interfaces";

export default class InputCtrl extends Component<IInputProps> {
  render() {
    let name = this.props.name;
    let rules: any = {
      required: this.props.required,
    };

    // Define default pattern and length rules
    switch (this.props.type) {
      case "text":
        rules.pattern = {
          value: /[A-Za-z0-9]{1,20}/,
          message: "Field can't be empty!",
        };
        // if (this.props.name === "companyWebsite") {
        //   rules.pattern = {
        //     value: /^https?:\/\/(www\.)?[a-zA-Z0-9-]+\.com$/,
        //     message: "Must be a valid .com website",
        //   };
        // } else {
        //   rules.pattern = {
        //     value: /[A-Za-z0-9]{1,20}/,
        //     message: "Field can't be empty!",
        //   };
        // }
        if (this.props.componentName === 'survey') {
          rules.minLength = {
            value: 1,
            message: "Must be 1 char long",
          };
        } else if (this.props.componentName === 'payment') {
          rules.minLength = {
            value: 16,
            message: "Must be 16 chars long",
          };
        }
        break;

      case "email":
        rules.pattern = {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: "Invalid email address",
        };
        rules.minLength = {
          value: 4,
          message: "Enter a valid email address",
        };
        break;

      case "tel":
        rules.pattern = {
          value: /[0-9]{10,14}/,
          message: "Invalid mobile number",
        };
        rules.minLength = {
          value: 10,
          message: "Must be 10 digits long!",
        };
        rules.maxLength = {
          value: 14,
          message: "Can use max 14 digits (Ex: +44 888888888888)!",
        };
        break;
      case "password":
        rules.minLength = {
          value: 8,
          message: "Must be at least 8 chars long",
        };
        rules.maxLength = {
          value: 16,
          message: "Can use a maximum of 16 chars",
        };
        break;

      case "number":
        if (this.props.componentName === 'login' || this.props.componentName === 'signup') {
          rules.minLength = {
            value: 10,
            message: "Must be at least 10 chars long",
          };
        }
        break;

      case "date":
        // Remove minLength and maxLength for date type
        delete rules.minLength;
        delete rules.maxLength;
        break;

      default:
        break;
    }

    // Remove minLength and maxLength if componentName is 'no-maxmin'
    if (this.props.componentName === 'no-maxmin') {
      delete rules.minLength;
      delete rules.maxLength;
    }

    return (
      <div className="controller-outer">

        {/*  */}
        {/* <div className="form-floating mb-3">
          <input type="email" className="form-control" placeholder='oke' />
          <label >Email address</label>
        </div> */}
        {/*  */}
        <Controller
          render={({ onChange, onBlur, value }) => (
            this.props.float ? (
              <div className="form-floating">
                <input onBlur={onBlur}
                  name={this.props.name}
                  onChange={onChange}
                  value={value}
                  type={this.props.type}
                  placeholder={this.props.placeholder}
                  className={this.props.className}
                  disabled={this.props.disabled}
                  id={this.props.id}
                  maxLength ={this.props?.maxLength}
                  onInput ={this?.props?.onInput}
                />
                <label>{this.props.placeholder}</label>
              </div>
            ) : (

                <input
                  onBlur={onBlur}
                  name={this.props.name}
                  onChange={onChange}
                  value={value}
                  type={this.props.type}
                  placeholder={this.props.placeholder}
                  className={this.props.className}
                  disabled={this.props.disabled}
                  maxLength ={this.props?.maxLength}
                  onInput ={this?.props?.onInput}

                />
              )
          )}
          name={this.props.name}
          control={this.props.control}
          rules={rules}
        />
        {this.props.children}
        {this.props.showError(name)}
      </div>
    );
  }
}
